import './user-messages.scss';

import {
  ajax,
  scrollIntoView
} from 'application/src/js/functions.js';

import {
  Mediator,
  Pagination,
  SpinnerOverlay,
  Textarea,
  transformTextareaInput
} from 'application/src/js/util.js';

import Message from './message.js';

const customElementSynchronizer = new Mediator();

customElements.define('user-messages', class extends HTMLElement {
  constructor() {
    super();

    this._translations = translations.webComponents.userMessages;

    this._itemId = this.getAttribute('item-id'); // dynamic_offer_id, request_id, smartcontract_offer_id
    this._itemType = this.hasAttribute('item-type') ? this.getAttribute('item-type').split(',') : null; // ['dynamic', 'request', 'smartcontract']
    if (this._itemType && this._itemType.length == 1) this._itemType = this._itemType[0];
    this._showFilter = !!parseInt(this.getAttribute('show-filter')); // 1 or falsy
    this._maxHeight = parseInt(this.getAttribute('max-height')) || null; // INT in 'em' or falsy
    this._appearance = this.getAttribute('appearance') || 'default'; // default|minimized|integrated
    this._showAttachments = !!parseInt(this.getAttribute('show-attachments')); // 1 or falsy
    this._showTextarea = !!parseInt(this.getAttribute('show-textarea')); // 1 or falsy
    this._canReply = this.hasAttribute('can-reply') ? !!parseInt(this.getAttribute('can-reply')) : true;
    this._orderDisabled = this.hasAttribute('order-disabled') ? !!parseInt(this.getAttribute('order-disabled')) : false;

    // if attribute exists but empty --> no messages are loaded
    // if attribute does not exist --> all messages are loaded
    // if attribute exists and has value (message ID) --> load this message
    this._loadMessageId = this.hasAttribute('load-message-id') ? !!parseInt(this.getAttribute('load-message-id')) : false;

    this._loadUrl = '/incursio/ajaxLoadMessage';
    this._sendMessageURL = '/incursio/ajaxSendMessage';
    this._replyUrl = '/incursio/ajaxReplyToMessage';
    this._updateStatusUrl = '/incursio/ajaxUpdateMessageProperties';

    this._hideClass = 'hide';
    this._debugMode = true;
    this._perPage = parseInt(this.getAttribute('per-page')) || 5;
    this._messagesData = [];
    this._totalAmount = 0;
    this._unseenMessages = [];
    this._currentUnseenMessages = [];
    this._unseenReplies = [];
    this._currentUnseenReplies = [];
    this._instances = []; // message instances

    this._lastSearchString = null;
    this._searchTimeout = null;
    this._poll = isNaN(parseInt(this.getAttribute('poll'))) ? true : !!parseInt(this.getAttribute('poll')); // polling enabled or disabled
    this._interval = null; // polling interval
    this._intervalTime = 1 * 60 * 1000; // the interval for refreshing/reloading messages
    this._id = new Date().getTime();

    this._setPageId();
  }

  static get observedAttributes() {
    return ['cuid', 'message-id'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'cuid': {
        this._addStaticEvents();
        this._pageIndex = 0;

        if (this._poll) {
          if (this._pageIsVisible) this._setActivePage();
          this.startPolling();
        }

        this._build();
        this._addEvents();
        this.reload();
        break;
      }

      case 'message-id': {
        this.updateMessageStatus(false);
      }
    }
  }

  disconnectedCallback() {
    this._translations = null;
    customElementSynchronizer.unsubscribe(this.tagName, this._synchCustomElements, this._id);
  }

  _loadMessages(showLoader = true) {
    if (this._loader && showLoader) this._loader.show();

    let requestParams = {
      company_user_id: this._cuid,
      page_index: this._pageIndex,
      per_page: this._perPage,
    };

    if (this._itemType) requestParams['item_type'] = this._itemType; // optional
    if (this._itemId) requestParams['item_id'] = this._itemId; // optional
    if (this._searchInput) requestParams['search_string'] = this._searchInput.value.trim(); // optional

    return ajax(this._loadUrl, requestParams);
  }

  _build() {
    this.innerHTML = this._template;

    this._filterMenu = this.querySelector('.filter-menu');
    this._outerList = this.querySelector('.outer');
    this._subjectInput = this.querySelector('input.subject');
    this._addMessageSubmitButton = this.querySelector('.send-message .button');
    this._list = this.querySelector('.list');
    this._searchInput = this.querySelector('input.search');
    this._newMessageInfo = this.querySelector('.new-msg-info');

    this._loader = new SpinnerOverlay({
      targetContainer: this._outerList,
      position: 'absolute',
      zIndex: 8
    });

    if(this._showTextarea) {
      this._sendMessageTextarea = new Textarea(this.querySelector('.send-message textarea'), {
        transformHTML: html => transformTextareaInput(html)
      });
    }

    this._pagination = new Pagination({
      container: this.querySelector('.pagination'),
      totalEntriesAmount: this._totalAmount,
      entriesPerPage: this._perPage,
      currentPageIndex: this._pageIndex,
      autoHide: true,
      noResultsMessage: this.querySelector('.no-results-message'),
    });
  }

  _afterLoad(response) {
    this._user = response.user;
    this._messagesData = response.data.messages;
    this._totalAmount = response.data.messages_amount;
    this._unseenMessages = response.data.unseen_messages;
    this._currentUnseenMessages = response.data.current_unseen_messages;
    this._unseenReplies = response.data.unseen_replies;
    this._currentUnseenReplies = response.data.current_unseen_replies;

    const scrollPos = this._list ? this._list.scrollTop : 0;

    this._render(scrollPos);

    this.dispatchEvent(
      new CustomEvent('afterLoad', {
        detail: {
          totalAmount: response.data.messages_amount,
          unseenMessages: response.data.unseen_messages,
          currentUnseenMessages: response.data.current_unseen_messages,
          unseenReplies: response.data.unseen_replies,
          currentUnseenReplies: response.data.current_unseen_replies
        }
      })
    );

    if (this._loader) this._loader.hide();
  }

  _addStaticEvents() {
    ['pageshow', 'mouseenter', 'visibilitychange', 'focus'].map(eventType => {
      document.addEventListener(eventType, _ => {
        if (this._pageIsVisible && this._poll) this._setActivePage();
      });
    });
  }

  _synchCustomElements() {
    if (e.caller === this) return;
    if (e.cuid !== this._cuid) return;
    this.reload();
  }

  _addEvents() {
    customElementSynchronizer.subscribe(this.tagName, this._synchCustomElements, { id: this._id });

    this._pagination.subscribe('pageChange', pageIndex => {
      this._pageIndex = pageIndex;
      this.reload();
      this._scrollToTop();
    });

    // [...this._typeCheckboxes].map(checkbox => {
    //   checkbox.addEventListener('change', _ => this._render());
    // });

    if (this._searchInput) {
      this._searchInput.addEventListener('input', _ => this._wait().then(_ => {
        if (this._lastSearchString === this._searchInput.value) return;
        this._lastSearchString = this._searchInput.value;
        this._pageIndex = 0;
        this.reload();
      }));
    }

    if (this._appearance == 'minimized' || this._appearance == 'integrated') {
      let scrollTimeout = null;

      this._list.addEventListener('scroll', e => {
        new Promise((resolve, reject) => {
          if (scrollTimeout) clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(_ => resolve(), 100);
        }).then(_ => {
          if (this._list.scrollTop < 50) this._newMessageInfo.classList.add(this._hideClass);
        })
      });

      this._newMessageInfo.addEventListener('click', _ => this._scrollToTop());
    }

    if (this._showTextarea) {
      this._sendMessageTextarea.subscribe('input', (textContent, html) => {
        this._addMessageSubmitButton.disabled = !textContent.trim().length;
      });

      this._addMessageSubmitButton.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target.disabled) return;
        e.target.disabled = true;

        this._sendMessage()
          .then(_ => {
            this._sendMessageTextarea.value = '';

            // notify other user-messages component instances
            // customElementSynchronizer.publish(this.tagName, {
            //   caller: this,
            //   cuid: this._cuid,
            // });

            this.reload();
          });
      });
    }
  }

  _wait() {
    return new Promise((resolve, reject) => {
      if (this._searchTimeout) clearTimeout(this._searchTimeout);
      this._searchTimeout = setTimeout(_ => resolve(), 300);
    });
  }

  _sendMessage() {
    this._loader.show();

    return ajax(this._sendMessageURL, {
      // receiver_company_user_id: 123, // optional (required if item_type and item_id are missing)
      // reason: !this._subjectInput.value.trim().length ? '' : this._subjectInput.value.trim(),
      item_id: this._itemId,
      item_type: this._itemType,
      message: !this._sendMessageTextarea.value.trim().length ? '' : this._sendMessageTextarea.html
    });
  }

  /**
   * @description scrolls to the top of the message list
   */
  _scrollToTop() {
    if (this._list.getBoundingClientRect().top < 0) {
      setTimeout(_ => scrollIntoView(this._list, 'top', 175, true, this._list.scrollTop > 0 ? this._list : window), 0);
    }
  }

  /**
   * @description saves an identifier for this tab/page in the sessionStorage in order to identify the active tab/page
   */
  _setPageId() {
    // ded_ma_mpid = DEDere Mundus Agri Message Page ID
    window.sessionStorage.setItem('ded_ma_mpid', Math.floor(Math.random() * 100) * new Date().getTime());
  }

  /**
   * @description sets the ID of the currently active component instance in localstorage
   */
  _setActivePage() {
    localStorage.setItem('ded_ma_mpid', window.sessionStorage.getItem('ded_ma_mpid'));
  }

  _render(scrollPos = 0) {
    this._list.innerHTML = '';
    this._pagination.update(this._totalAmount, this._perPage, this._pageIndex);

    const messagesWithVisibleReplies = this._instances.reduce((ids, instance) => {
      if (instance.repliesVisible) ids.push(instance.data.message_id);
      return ids;
    }, []);

    this._instances = []; // empty messages array

    this._messagesData.map(messageData => {
      const messageElement = document.createElement('div');
      this._list.insertAdjacentElement('beforeend', messageElement);

      this._instances.push(
        new Message({
          parent: this,
          element: messageElement,
          data: messageData,
          translations: this._translations,
          user: this._user,
          replyUrl: this._replyUrl,
          globalLoader: this._loader,
          debugMode: this._debugMode,
          showAttachments: this._showAttachments,
          appearance: this._appearance,
          isFolded: this.messageId !== messageData.message_id,
          repliesVisible: messagesWithVisibleReplies.indexOf(messageData.message_id) != -1,
          canReply: this._canReply,
          orderDisabled: this._orderDisabled,
        })
      );
    });

    if (this._newMessageInfo && scrollPos > 50 && this._currentNewMessageInstances.length) {
      this._newMessageInfo.classList.remove(this._hideClass);
    }

    if (this._filterMenu) this._filterMenu.classList[this._totalAmount > 0 ? 'remove' : 'add'](this._hideClass);

    if (scrollPos) this._list.scrollTop = scrollPos;
  }

  /**
   * @param {number} messageId the ID of the currently active (expanded) message
   */
  get messageId() {
    return parseInt(this.getAttribute('message-id')) || 0;
  }

  /**
   * @param {number=} messageId
   */
  set messageId(messageId = null) {
    return this.setAttribute('message-id', messageId || 0);
  }

  /**
   * @returns {boolean} the page is active when it is visible (browser window not minimized, active tab)
   */
  get _pageIsVisible() {
    return document.visibilityState == 'visible';
  }

  /**
   * @returns {boolean} true if the component's ID equals the instance id in localStorage
   */
  get _pageIsActive() {
    return parseInt(localStorage.getItem('ded_ma_mpid')) === parseInt(window.sessionStorage.getItem('ded_ma_mpid'));
  }

  /**
   * @returns {number} company_user_id
   */
  get _cuid() {
    return parseInt(this.getAttribute('cuid'));
  }

  /**
   * @returns {array} all current pagination-page's messages with seen=0 and read=0
   */
  get _currentNewMessageInstances() {
    return this._instances.filter(message => message.data.seen === 0 && message.data.read === 0);
  }

  /**
   * @returns {array} IDs of all replies from others users with seen=0
   */
  get _unseenForeignReplyIds() {
    return this._messagesData.reduce((ids, message) => {
      ids = [...ids, ...message.replies.filter(reply => {
        return reply.seen === 0 && reply.company_user_id !== this._user.company_user_id;
      }).map(reply => reply.message_id)];
      return ids;
    }, []);
  }

  get _filterMenuTemplate() {
    return /*html*/`
      <div class="filter-menu ${this._hideClass}">
        <div>
          <label>${this._translations.search}</label>
          <div class="input-wrap">
            <input type="text" class="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="hide">
          <div class="uic-pretty-checkbox">
            <input type="checkbox" id="sent-user-messages" value="sent" checked>
            <label for="sent-user-messages"></label>
          </div>
          <label for="sent-user-messages">${this._translations.sent}</label>
          &nbsp;
          <div class="uic-pretty-checkbox">
            <input type="checkbox" id="received-user-messages" value="received" checked>
            <label for="received-user-messages"></label>
          </div>
          <label for="received-user-messages">${this._translations.received}</label>
        </div>
      </div>
    `;
  }

  get _template() {
    return /*html*/`
			<div class="outer">
        ${this._appearance == 'minimized' || this._appearance == 'integrated' ? `
          <div class="new-msg-info ${this._hideClass}">${this._translations.newMessage}</div>
        ` : ``}

        ${this._showFilter ? this._filterMenuTemplate : ``}

        ${!this._showTextarea ? `<div class="no-results-message">${this._translations.noResults}</div>` : `
          <div class="send-message">
            <input type="text" max-length="150" class="subject hide" disabled placeholder="${this._translations.subject}">
            <textarea placeholder="${this._translations.yourMessage}..."><br></textarea>
            <div class="text-right">
              <button class="button button-submit" disabled>${this._translations.send}</button>
            </div>
          </div>
        `}

        <div class="list" ${this._maxHeight ? `style="max-height:${this._maxHeight}em"` : ``}></div>
				<div class="pagination pagination-right">
					<div class="page-nav">
						<div class="prev-page"><i class="fas fa-angle-left"></i></div>
						<div class="page-buttons"></div>
						<div class="next-page"><i class="fas fa-angle-right"></i></div>
					</div>
				</div>
      </div>
    `;
  }

  reload(showLoader = true) {
    this._loadMessages(showLoader)
      .then(response => this._afterLoad(response))
      .catch(e => {
        if (this._debugMode && e) console.log(e);
        if (this._loader) this._loader.hide();
      });
  }

  stopPolling() {
    if (this._interval) clearInterval(this._interval);
  }

  startPolling() {
    this.stopPolling();

    this._interval = setInterval(_ => {
      if (this._pageIsActive && !this._currentInstance) this.reload(false);
    }, this._intervalTime);
  }

  get _currentInstance() {
    let instance = null;

    if (this.messageId) {
      this._instances.map(message => {
        if (message.data.message_id === this.messageId) {
          instance = message;
        }
      });
    }

    return instance;
  }

  /**
   * fires an ajax request in order to set the 'seen' and 'read' properties to 1
   * triggers rendering
   * @param {boolean=} saveAll if false, only the unseen messages of the current pagination-page are saved
   */
  updateMessageStatus(saveAll = true) {
    if (!this._messagesData.length) return;

    let unseenIds = saveAll ? this._unseenMessages : this._currentUnseenMessages;
    let unreadIds = [];

    const currentInstance = this._currentInstance;

    if (currentInstance) {
      const unseenReplyIds = currentInstance.replies
                              .filter(reply => reply.data.seen === 0 && !this.isCreatorOf(reply.data))
                              .map(reply => reply.data.message_id);

      const unreadReplyIds = currentInstance.replies
                              .filter(reply => reply.data.read === 0 && !this.isCreatorOf(reply.data))
                              .map(reply => reply.data.message_id);

      unseenIds.push(...unseenReplyIds);
      unreadIds.push(...unseenReplyIds, ...unreadReplyIds);

      if (currentInstance.data.read === 0 && !this.isCreatorOf(currentInstance.data)) unreadIds.push(currentInstance.data.message_id);
    }

    if (unseenIds.length || unreadIds.length) {
      ajax(this._updateStatusUrl, {
          seen: unseenIds,
          read: unreadIds
        })
        .then(_ => {
          // update base data
          this._messagesData.map(message => {
            if (unseenIds.indexOf(message.message_id) != -1) message.seen = 1;
            if (unreadIds.indexOf(message.message_id) != -1) message.read = 1;

            message.replies.map(reply => {
              if (unseenIds.indexOf(reply.message_id) != -1) reply.seen = 1;
              if (unreadIds.indexOf(reply.message_id) != -1) reply.read = 1;
            });
          });

          this._unseenMessages = this._unseenMessages.filter(id => unseenIds.indexOf(id) < 0);
          this._currentUnseenMessages = this._currentUnseenMessages.filter(id => unseenIds.indexOf(id) < 0);

          this._unseenReplies = this._unseenReplies.filter(id => unseenIds.indexOf(id) < 0);
          this._currentUnseenReplies = this._currentUnseenReplies.filter(id => unseenIds.indexOf(id) < 0);

          this._render();

          // notify other user-messages component instances
          customElementSynchronizer.publish(this.tagName, {
            caller: this,
            cuid: this._cuid,
          });

          this.dispatchEvent(
            new CustomEvent('afterSave', {
              detail: {
                unseenMessages: this._unseenMessages,
                currentUnseenMessages: this._currentUnseenMessages,
                unseenReplies: this._unseenReplies,
                currentUnseenReplies: this._currentUnseenReplies
              }
            })
          );
        })
        .catch(e => {
          if (this._debugMode && e) console.log(e);
          if (this._loader) this._loader.hide();
        });
    } else {
      this._render();
    }
  }

  isCreatorOf(itemData) {
    return this._user.company_user_id === itemData.sender_company_user_id;
  }

  onEditMessage(messageData) {
    this._messagesData = this._messagesData.reduce((messages, message) => {
      messages.push(message.message_id === messageData.message_id ? messageData : message)
      return messages;
    }, []);
  }

  onDeleteMessage() {
    this.reload();
  }
});