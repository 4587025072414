import {
  ajax,
  getElapsedTimeText,
  dateTimeToISO
} from 'application/src/js/functions.js';

import {
  ConfirmOverlay,
  FlyoutMenu,
  SpinnerOverlay,
  Textarea,
  transformTextareaInput
} from 'application/src/js/util.js';

import Reply from './reply.js';

/**
 * @param {node} parent user messages web component node object
 * @param {node} element message element
 * @param {object} data data object for this message
 * @param {object} translations text translations object
 * @param {string} replyUrl ajax URL for adding a reply
 * @param {boolean} debugMode debugging enabled/disabled
 * @param {boolean} showAttachments defines wheather to display the attachment or not
 * @param {string} appearance default|minimized|integrated
 * @param {SpinnerOverlay} globalLoader parent loader
 * @param {boolean} isFolded defines wheather to display the message details or not. Only for 'appearance'='minimized' and 'appearance'='integrated'!
 * @param {string} env defines the project environment, e.g. 'admin'
 * @param {boolean} canReply defines wheather users can reply
 * @param {boolean} orderDisabled defines wheather trade-items can have the place-order components and hence can be ordered
 */
export default class Message {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.data = args.data;
    this.translations = args.translations;
    this.user = args.user;
    this.replyUrl = args.replyUrl;
    this.debugMode = args.debugMode;
    this.showAttachments = args.showAttachments;
    this.appearance = args.appearance;
    this.globalLoader = args.globalLoader;
    this.isFolded = args.isFolded;
    this.canReply = args.canReply;
    this.orderDisabled = args.orderDisabled;

    if (this.appearance == 'minimized' || this.appearance == 'integrated') {
      this.repliesVisible = true;
    } else {
      this.repliesVisible = args.repliesVisible;
    }

    this.loader = null; // the position:absolute loader
    this.hideClass = 'hide'; // css class used in order to hide elements
    this.openClass = 'open'; // css class applied when the body is visible (only if this.appearance == 'minimized' or this.appearance == 'integrated')
    this.seenClass = 'seen'; // css class applied when the user has seen the message
    this.readClass = 'read'; // css class applied when the user has read (opened) the message
    this.hasUnseenRepliesClass = 'has-unseen'; //
    this.hasUnreadRepliesClass = 'has-unread'; //
    this.numInitialChars = 350; // number of initially visible content chars
    this.replies = [];
    this.editMode = false; // when editing a post this is true
    this.editUrl = '/incursio/ajaxEditMessage';
    this.deleteUrl = '/incursio/ajaxDeleteMessage';

    this.sortRepliesByDate();
    this.render();
  }

  addEvents() {
    if (this.appearance == 'minimized' || this.appearance == 'integrated') {
      this.headElement.addEventListener('click', e => {
        e.stopPropagation();

        if (!this.isFolded) {
          this.hideDetails();
          this.parent.messageId = null;
        } else {
          this.showDetails();
          this.parent.messageId = this.data.message_id;
        }
      });
    }

    [...this.element.querySelectorAll('.profile-info a')].map(element => {
      element.addEventListener('click', e => e.stopPropagation());
    })

    if (this.postReplyTextarea) {
      this.postReplyTextarea.subscribe('input', value => {
        this.submitReplyButton.disabled = !this.postReplyTextarea.value.trim().length;
      });
    }

    if (this.showTextareaButton) {
      this.showTextareaButton.addEventListener('click', _ => {
        this.showTextareaButton.classList.add(this.hideClass);
        this.textareaContainer.classList.remove(this.hideClass);
        this.postReplyTextarea.focus();
        this.parent.stopPolling();
      });
    }

    if (this.cancelPostButton) {
      this.cancelPostButton.addEventListener('click', _ => {
        this.textareaContainer.classList.add(this.hideClass);
        if (this.showTextareaButton) this.showTextareaButton.classList.remove(this.hideClass);
        this.parent.startPolling();
      });
    }

    if (this.showRepliesButton) {
      this.showRepliesButton.addEventListener('click', _ => {
        this.repliesVisible = true;
        this.showRepliesButton.classList.add(this.hideClass);
        this.hideRepliesButton.classList.remove(this.hideClass);
        this.repliesContainer.classList.remove(this.hideClass);
      });
    }

    if (this.hideRepliesButton) {
      this.hideRepliesButton.addEventListener('click', _ => {
        this.repliesVisible = false;
        this.hideRepliesButton.classList.add(this.hideClass);
        this.showRepliesButton.classList.remove(this.hideClass);
        this.repliesContainer.classList.add(this.hideClass);
      });
    }

    if (this.showAllContentButton) {
      this.showAllContentButton.addEventListener('click', _ => {
        this.showAllContentButton.classList.add(this.hideClass);
        this.ellipsis.classList.add(this.hideClass);
        this.hiddenContent.classList.remove(this.hideClass);
      });
    }

    if (this.submitReplyButton) {
      this.submitReplyButton.addEventListener('click', _ => {
        if (this.requestRunning) return;
        this.requestRunning = true;
        this.showLoader();

        ajax(this.replyUrl, {
            parent_message_id: this.data.message_id,
            message: !this.postReplyTextarea.value.trim().length ? '' : this.postReplyTextarea.html,
            message_channel_id: this.data.message_channel_id || '',
          }).then(response => {
            this.data.replies.push(response.data);
            this.postReplyTextarea.value = '';
            this.submitReplyButton.disabled = true;
            this.sortRepliesByDate();
            this.render();
            this.requestRunning = false;
          })
          .catch(e => {
            if (this.debugMode) console.log(e);
          });
      });
    }

    if (this.userCanDelete) {
      if (this.deleteMessageButton) {
        this.deleteMessageButton.addEventListener('click', _ => this.deleteConfirmOverlay.open());

        this.deleteConfirmOverlay.subscribe('confirm', _ => {
          this.showLoader(true);
          this.deleteConfirmOverlay.close();
          if (this.requestRunning) return;
          this.requestRunning = true;

          ajax(this.deleteUrl, {
              message_id: this.data.message_id
            }).then(_ => {
              this.parent.onDeleteMessage();
              this.requestRunning = false;
            })
            .catch(e => {
              if (this.debugMode && e) console.log(e);
              this.hideLoader(true);
            });
        });

        this.deleteConfirmOverlay.subscribe('cancel', overlay => overlay.close());
      }
    }

    if (this.userCanEdit) {
      if (this.editMessageButton) {
        this.editMessageButton.addEventListener('click', _ => {
          setTimeout(_ => {
            this.editMode = true;
            this.render();
            this.editMessageTextarea.focus();
          }, 0);
        });
      }

      if (this.editMode) {
        this.editMessageTextarea.subscribe('input', value => {
          this.saveEditMessageButton.disabled = !this.editMessageTextarea.value.trim().length;
        });

        this.cancelEditPostButton.addEventListener('click', _ => {
          setTimeout(_ => {
            this.editMode = false;
            this.render();
          }, 0);
        });

        this.saveEditMessageButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          if (e.target.disabled) return;
          e.target.disabled = true;

          this.showLoader();

          ajax(this.editUrl, {
              message_id: this.data.message_id,
              message: !this.editMessageTextarea.value.trim().length ? '' : this.editMessageTextarea.html,
            }).then(response => {
              this.data = Object.assign({}, this.data, response.data);
              this.editMode = false;
              this.render();
              this.hideLoader();
              this.requestRunning = false;
              this.parent.onEditMessage(this.data);
            })
            .catch(e => {
              if (this.debugMode && e) console.log(e);
              this.hideLoader();
            });
        });
      }
    }
  }

  hideDetails() {
    this.isFolded = true;
    this.render();
  }

  showDetails() {
    this.isFolded = false;
    this.render();
  }

  update(data) {
    this.data = data;
    this.render();
  }

  render() {
    this.element.classList.add('message');
    this.element.classList[this.isFolded ? 'remove' : 'add'](this.openClass);
    this.element.classList[this.data.seen || this.isCreator ? 'add' : 'remove'](this.seenClass);
    this.element.classList[this.data.read || this.isCreator ? 'add' : 'remove'](this.readClass);
    this.element.classList[this.hasUnseenReplies ? 'add' : 'remove'](this.hasUnseenRepliesClass);
    this.element.classList[this.hasUnreadReplies ? 'add' : 'remove'](this.hasUnreadRepliesClass);

    this.element.setAttribute('data-id', this.data.message_id);
    this.element.innerHTML = this.template;

    if (this.editMode) {
      this.editMessageTextarea = new Textarea(this.element.querySelector('.msg-outer-content textarea'), {
        transformHTML: html => transformTextareaInput(html)
      });
      this.saveEditMessageButton = this.element.querySelector('.msg-outer-content .button-save');
      this.cancelEditPostButton = this.element.querySelector('.msg-outer-content .button-cancel');
      this.editMessageTextarea.focus();
    }

    this.deleteConfirmOverlay = new ConfirmOverlay({
      container: this.element,
      headline: translations.deleteConfirm.headline,
      buttonConfirmLabel: translations.deleteConfirm.delete,
      buttonConfirmColorClass: 'alert',
      buttonCancelLabel: translations.deleteConfirm.cancel,
      position: 'fixed'
    });

    this.cancelPostButton = this.element.querySelector('.enter-comment .button-cancel');
    this.editMessageButton = this.element.querySelector('.button-edit');
    this.deleteMessageButton = this.element.querySelector('.button-delete');

    if (this.hasMenu) this.menu = new FlyoutMenu({
      element: this.element.querySelector('.flyout-menu')
    });

    this.repliesContainer = this.element.querySelector('.replies-container');
    this.replies = [];

    this.data.replies.map(replyData => {
      const replyElement = document.createElement('div');
      this.repliesContainer.insertAdjacentElement('beforeend', replyElement);

      this.replies.push(
        new Reply({
          parent: this,
          element: replyElement,
          user: this.user,
          data: replyData,
          translations: this.translations,
          debugMode: this.debugMode,
          editUrl: this.editUrl,
          deleteUrl: this.deleteUrl
        })
      );
    })

    if (this.hasReplies) {
      this.showRepliesButton = this.element.querySelector('.show-replies');
      this.hideRepliesButton = this.element.querySelector('.hide-replies');
    }

    this.headElement = this.element.querySelector('.msg-head');
    this.bodyElement = this.element.querySelector('.msg-body');
    this.submitReplyButton = this.element.querySelector('.button-submit');
    this.cancelPostButton = this.element.querySelector('.enter-reply .button-cancel');
    this.showTextareaButton = this.element.querySelector('.show-reply-textarea');
    this.textareaContainer = this.element.querySelector('.enter-reply');
    this.showAllContentButton = this.element.querySelector('.show-all');
    this.ellipsis = this.element.querySelector('.ellipsis');
    this.hiddenContent = this.element.querySelector('.post-hidden-content');
    this.attachmentsContainer = this.element.querySelector('.msg-attachments');

    if (this.showAttachments) this.addAttachment();

    this.loader = new SpinnerOverlay({
      targetContainer: this.element,
      position: 'absolute'
    });

    if (this.element.querySelector('.enter-reply textarea')) {
      this.postReplyTextarea = new Textarea(this.element.querySelector('.enter-reply textarea'), {
        transformHTML: html => transformTextareaInput(html)
      });
    }

    this.addEvents();
  }

  addAttachment() {
    this.attachmentsContainer.innerHTML = '';

    if (this.data.created_date === null) return; // if created_date is null, dataset is broken or deleted

    const attachmentElement = document.createElement('div');
    this.attachmentsContainer.insertAdjacentElement('beforeend', attachmentElement);

    const options = {
      parent: this,
      element: attachmentElement,
      data: this.data.item,
      translations: this.translations,
      user: this.user
    };

    switch (this.data.type) {
      case 'dynamic':
        import( /* webpackChunkName: "dynamic-offer" */ 'application/src/js/trade-items/dynamic-offer.js')
          .then(({
            default: DynamicOffer
          }) => {
            new DynamicOffer(Object.assign({}, options, {
              orderDisabled: this.orderDisabled
            }));
          });
        break;
      case 'contract':
        import( /* webpackChunkName: "contract-offer" */ 'application/src/js/trade-items/contract-offer.js')
          .then(({
            default: ContractOffer
          }) => new ContractOffer(options));
        break;
      case 'request':
        import( /* webpackChunkName: "enquiry" */ 'application/src/js/trade-items/enquiry.js')
          .then(({
            default: Enquiry
          }) => new Enquiry(options));
        break;
      case 'smartcontract':
        import( /* webpackChunkName: "smart-contract" */ 'application/src/js/smart-contract/smart-contract.js')
          .then(({
            default: SmartContract
          }) => {
            new SmartContract(Object.assign({}, options, {
              showAdditionalDetails: false,
              showUserMessages: false,
              showMenu: false,
              allowUpload: false,
              compact: true,
              hasLinkedTitle: true
            }));
          });
    }
  }

  showLoader(globalLoader = false) {
    this[globalLoader ? 'globalLoader' : 'loader'].show();
  }

  hideLoader(globalLoader = false) {
    this[globalLoader ? 'globalLoader' : 'loader'].hide();
  }

  sortRepliesByDate() {
    this.data.replies.sort((a, b) => b.created_date.timestamp - a.created_date.timestamp);
  }

  replaceTagsWithWhitespace(htmlString) {
    return htmlString.replace(/<(.|\n)*?>/g, ' ');
  }

  isOwnReply(replyData) {
    return this.user.company_user_id === replyData.sender_company_user_id;
  }

  get hasUnseenReplies() {
    return this.data.replies.filter(replyData => replyData.seen === 0 && !this.isOwnReply(replyData)).length > 0;
  }

  get hasUnreadReplies() {
    return this.data.replies.filter(replyData => replyData.read === 0 && !this.isOwnReply(replyData)).length > 0;
  }

  get content() {
    const cutIndex = this.data.message.indexOf(' ', this.numInitialChars);

    return cutIndex == -1 ? this.data.message : `
      ${this.data.message.substring(0, cutIndex)}
      <span class="ellipsis">...</span>
      <a class="show-all">${this.translations.readMore}</a>
      <span class="post-hidden-content ${this.hideClass}">${this.data.message.substring(cutIndex + 1)}</span>
    `;
  }

  get languageISO() {
    return document.querySelector('meta[name="language-iso"]').getAttribute('content');
  }

  get elapsedTime() {
    // if posted within the last hour...
    if (new Date().getTime() - new Date(this.data.created_date).getTime() < (1000 * 60 * 60)) {
      return getElapsedTimeText(new Date(this.data.created_date).getTime(), this.translations.elapsedTime);
    }

    // show only date if minimized
    if (this.appearance == 'minimized' || this.appearance == 'integrated') {
      return new Intl.DateTimeFormat(this.languageISO, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(dateTimeToISO(this.data.created_date)));
    }

    return new Intl.DateTimeFormat(this.languageISO, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(new Date(dateTimeToISO(this.data.created_date)));
  }

  get hasReplies() {
    return this.data.replies && this.data.replies.length;
  }

  get isCreator() {
    return this.user.company_user_id === this.data.sender_company_user_id;
  }

  get hasMenu() {
    return this.isCreator;
  }

  get userCanEdit() {
    return this.isCreator;
  }

  get userCanDelete() {
    return this.isCreator;
  }

  get flyoutMenuTemplate() {
    return `
      <div class="flyout-menu left middle ${this.editMode ? this.hideClass : ''}">
        <a class="flyout-trigger"><i class="fas fa-ellipsis-v"></i></a>
        <ul>
          ${this.userCanEdit ? `
            <li>
              <a class="button-edit" title="${this.translations.edit}">
                <span>${this.translations.edit}</span>
              </a>
            </li>
          `: `` }

          ${this.userCanDelete ? `
            <li>
              <a class="button-delete" title="${this.translations.delete}">
                <span>${this.translations.delete}</span>
              </a>
            </li>
          `: `` }
        </ul>
      </div>
    `;
  }

  get template() {
    return `
      <div class="msg-head">
        <div class="profile-image">
          <a href="/mundus-agri/addressDetails/${this.data.global_address_id}?tabid=3&gacid=${this.data.global_address_contact_id}" style="background-image:url(/images/profile_images/${this.data.sender_company_user_id}.png);">
            <i class="fas fa-user"></i>
          </a>
        </div>
        <div class="profile-info">
          <div class="top">
            <a href="/mundus-agri/addressDetails/${this.data.global_address_id}?tabid=3&gacid=${this.data.global_address_contact_id}">
              ${this.data.firstname} ${this.data.lastname}
            </a>
            ${this.data.company_name ? `
              <a href="/mundus-agri/addressDetails/${this.data.global_address_id}" class="company-name" title="${this.data.company_name}">${this.data.company_name}</a>
            ` : ''}
          </div>
          <span class="date">${this.elapsedTime}</span>
        </div>
        ${(this.appearance == 'minimized' || this.appearance == 'integrated') && this.isFolded ? `
          <div class="top-content">
            ${this.data.reason ? `<div><b>${this.data.reason}</b></div>` : ''}
            <div>${this.replaceTagsWithWhitespace(this.content)}</div>
            ${this.data.replies.length ? `
              <div class="num-replies">
                ${this.data.replies.length}
                ${this.data.replies.length > 1 ? this.translations.answers : this.translations.answer}
              </div>` : ''}
          </div>
          ` : ''}
      </div>
      <div class="msg-body ${(this.appearance == 'minimized' || this.appearance == 'integrated') && this.isFolded ? this.hideClass : ''}">
        <div class="msg-outer-content">
          <div class="msg-content">
            ${this.data.reason ? `<div class="msg-subject"><b>${this.data.reason}</b></div>` : ''}

            ${this.editMode ? `
              <textarea placeholder="${this.translations.yourMessage}...">${this.data.message}</textarea>
              ` : `<div class="msg-text">${this.content}</div>`
            }

            ${this.hasMenu ? this.flyoutMenuTemplate : ''}
          </div>

          ${this.editMode ? `
            <div class="msg-buttons text-right">
              <a class="button hollow button-cancel" title="${this.translations.cancel}">${this.translations.cancel}</a>
              <a class="button button-save" title="${this.translations.save}">${this.translations.save}</a>
            </div>
          ` : ''}

          ${this.showAttachments ? `
            <div class="msg-attachments"></div>
          ` : ''}

        </div>
        ${this.canReply || this.hasReplies ? `
          <div class="outer-replies">
            <div class="replies-container ${this.repliesVisible ? `` : this.hideClass}"></div>
            <div class="lower-replies">
              ${this.canReply ? `
                <a class="show-reply-textarea">
                  <i class="fas fa-reply"></i>
                  <span>${this.translations.addReply}</span>
                </a>
              ` : ''}

              ${this.hasReplies ? `
                <a class="show-replies ${this.repliesVisible ? this.hideClass : ''}">
                  <span>${this.data.replies.length} ${this.data.replies.length > 1 ? this.translations.showReplies : this.translations.showReply}</ span>
                </a>
                <a class="hide-replies ${this.repliesVisible ? `` : this.hideClass}">
                  <span>${this.translations.hideReplies}</span>
                </a>
              ` : ''}

              ${this.canReply ? `
                <div class="enter-reply ${this.hideClass}">
                  <textarea placeholder="${this.translations.yourReply}..."></textarea>
                  <div class="post-menu">
                    <button class="button hollow button-cancel">${this.translations.cancel}</button>
                    <button class="button button-submit" disabled>${this.translations.addReply}</button>
                  </div>
                </div>
              ` : ''}
            </div>
          </div>
        ` : ''}
      </div>
    `;
  }

  onDeleteReply() {
    this.parent.onDeleteMessage();
  }

  onEditReply(replyData) {
    this.data.replies = this.data.replies.reduce((replies, reply) => {
      replies.push(reply.message_id === replyData.message_id ? replyData : reply)
      return replies
    }, []);

    this.parent.onEditMessage(this.data);
  }
}