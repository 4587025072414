import {
  ajax,
  getElapsedTimeText,
  dateTimeToISO
} from 'application/src/js/functions.js';

import {
  ConfirmOverlay,
  FlyoutMenu,
  Textarea,
  transformTextareaInput
} from 'application/src/js/util.js';

/**
 * Class for a reply
 * @param { object } args.parent - the instance of the parent/creator class
 * @param { node } args.element - the outer html element of this reply
 * @param { object } args.data - the data for this reply
 * @param { object } args.translations - the translations / texts
 */

export default class Reply {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.user = args.user;
    this.data = args.data;
    this.translations = args.translations;
    this.debugMode = args.debugMode;
    this.editUrl = args.editUrl;
    this.deleteUrl = args.deleteUrl;

    this.element.classList.add('reply');

    this.hideClass = 'hide'; // css class used in order to hide elements
    this.numInitialChars = 350; // number of initially visible content chars
    this.editMode = false;

    this.render();
  }

  addEvents() {
    if(this.userCanDelete) {
      if(this.deleteReplyButton) {
        this.deleteReplyButton.addEventListener('click', e => {
          this.deleteConfirmOverlay.open();
        });

        this.deleteConfirmOverlay.subscribe('confirm', _ => {
          if (this.requestRunning) return;
          this.requestRunning = true;
          this.parent.showLoader(true);
          this.deleteConfirmOverlay.close();

          ajax(this.deleteUrl, {
            message_id: this.data.message_id
          }).then(_ => {
              this.parent.onDeleteReply();
              this.requestRunning = false;
            })
            .catch(e => {
              if (this.debugMode && e) console.log(e);
              this.parent.hideLoader(true);
            });
        });

        this.deleteConfirmOverlay.subscribe('cancel', overlay => overlay.close());
      }
    }

    if(this.userCanEdit) {
      if(this.editReplyButton) {
        this.editReplyButton.addEventListener('click', _ => {
          setTimeout(_ => {
            this.editMode = true;
            this.render();
            this.editReplyTextarea.focus();
          }, 0);
        });
      }

      if(this.editMode) {
        this.editReplyTextarea.subscribe('input', value => {
          this.saveEditReplyButton.disabled = !this.editReplyTextarea.value.trim().length;
        });

        this.cancelEditMessageButton.addEventListener('click', _ => {
          setTimeout(_ => {
            this.editMode = false;
            this.render();
          }, 0);
        });

        this.saveEditReplyButton.addEventListener('click', _ => {
          if(this.requestRunning) return;
          this.requestRunning = true;
          this.parent.showLoader();

          ajax(this.editUrl, {
            message_id: this.data.message_id,
            message: !this.editReplyTextarea.value.trim().length ? '' : this.editReplyTextarea.html,
          })
            .then(response => {
              this.data = Object.assign({}, this.data, response.data);
              this.editMode = false;
              this.render();
              this.parent.hideLoader();
              this.requestRunning = false;
              this.parent.onEditReply(this.data);
            })
            .catch(e => {
              if (this.debugMode && e) console.log(e);
              this.parent.hideLoader();
            });
        });
      }
    }
  }

  render() {
    this.element.innerHTML = this.template;

    if(this.editMode) {
      this.editReplyTextarea = new Textarea(this.element.querySelector('.msg-outer-content textarea'), {
        transformHTML: html => transformTextareaInput(html)
      });
      this.saveEditReplyButton = this.element.querySelector('.msg-outer-content .button-save');
      this.cancelEditMessageButton = this.element.querySelector('.msg-outer-content .button-cancel');
      this.editReplyTextarea.focus();
    }

    this.deleteConfirmOverlay = new ConfirmOverlay({
      container: this.element,
      headline: translations.deleteConfirm.headline,
      buttonConfirmLabel: translations.deleteConfirm.delete,
      buttonConfirmColorClass: 'alert',
      buttonCancelLabel: translations.deleteConfirm.cancel,
      position: 'fixed'
    });

    this.editReplyButton = this.element.querySelector('.button-edit');
    this.deleteReplyButton = this.element.querySelector('.button-delete');

    if(this.hasMenu) this.menu = new FlyoutMenu({ element: this.element.querySelector('.flyout-menu') });

    this.addEvents();
  }

  get content() {
    const cutIndex = this.data.message.indexOf(' ', this.numInitialChars);

    return cutIndex == -1 ? this.data.message : `
      ${this.data.message.substring(0, cutIndex)}
      <span class="ellipsis">...</span>
      <a class="show-all">${this.translations.showAll}</a>
      <span class="post-hidden-content ${this.hideClass}">${this.data.message.substring(cutIndex + 1)}</span>
    `;
  }

  get elapsedTime() {
    // if replied within the last hour...
    if (new Date().getTime() - new Date(this.data.created_date).getTime() < (1000 * 60 * 60)) {
      return getElapsedTimeText(new Date(this.data.created_date).getTime(), this.translations.elapsedTime);
    }

    return new Intl.DateTimeFormat(this.languageISO, { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(new Date(dateTimeToISO(this.data.created_date)));
  }

  get isCreator() {
    return this.user.company_user_id === this.data.sender_company_user_id;
  }

  get hasMenu() {
    return this.isCreator;
  }

  get userCanEdit() {
    return this.isCreator;
  }

  get userCanDelete() {
    return this.isCreator;
  }

  get flyoutMenuTemplate() {
    return `
      <div class="flyout-menu left middle ${this.editMode ? this.hideClass : ''}">
        <a class="flyout-trigger"><i class="fas fa-ellipsis-v"></i></a>
        <ul>
          ${this.userCanEdit ? `
            <li>
              <a class="button-edit" title="${this.translations.edit}">
                <span>${this.translations.edit}</span>
              </a>
            </li>
          `: `` }

          ${this.userCanDelete ? `
            <li>
              <a class="button-delete" title="${this.translations.delete}">
                <span>${this.translations.delete}</span>
              </a>
            </li>
          `: `` }
        </ul>
      </div>
    `;
  }

  get template() {
    return `
      <div class="msg-head">
        <div class="profile-image">
          <a href="/mundus-agri/addressDetails/${this.data.global_address_id}?tabid=3&gacid=${this.data.global_address_contact_id}" style="background-image:url(/images/profile_images/${this.data.sender_company_user_id}.png);">
            <i class="fas fa-user"></i>
          </a>
        </div>
        <div class="profile-info">
          <div class="top">
            <a href="/mundus-agri/addressDetails/${this.data.global_address_id}?tabid=3&gacid=${this.data.global_address_contact_id}">
              ${this.data.firstname} ${this.data.lastname}
            </a>
            ${this.data.company_name ? `
              <a href="/mundus-agri/addressDetails/${this.data.global_address_id}" class="company-name">${this.data.company_name}</a>
            ` : ''}
          </div>
          <span class="date">${this.elapsedTime}</span>
        </div>
      </div>
      <div class="msg-outer-content">
        <div class="msg-content">
          ${this.editMode ? `
            <textarea placeholder="${this.translations.yourMessage}...">${this.data.message}</textarea>
          ` : `<div class="msg-text">${this.content}</div>`}
          ${this.hasMenu ? this.flyoutMenuTemplate : ''}
        </div>
        ${this.editMode ? `
          <div class="msg-buttons text-right">
            <a class="button hollow button-cancel" title="${this.translations.cancel}">${this.translations.cancel}</a>
            <a class="button button-save" title="${this.translations.save}">${this.translations.save}</a>
          </div>
        ` : ''}
      </div>
    `;
  }
}